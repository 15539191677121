/* requires:
polyfill.js
cart.js
*/

// we're receiving the products object as a string so we need to parse it
const Products = JSON.parse('products_replaced_during_build');

// the product IDs order is the order they will appear in the bulk order form
// const bulkProductIds = ['a4a740e9-b6e8-4aca-827c-cdd99a0cb01d', '87c1641b-ba03-4ec2-96d9-3ec059594d75'];
const bulkProductIds = [Products.flMatDr, Products.caInitMatDr, Products.caRenewMatDr, Products.vaMatDr];

const bulkOrder = {
  bulkBox: document.querySelector('#bulk-box'),
  bulkTable: document.querySelector('#bulk-table'),
  expandedDescription: [],

  init: () => {
    bulkOrder.renderProducts(bulkProductIds);
  },

  formatAmount: (number) => {
    return Number(Math.round(number + 'e2') + 'e-2');
  },

  renderProducts: (bulkProductIds) => {
    // render products in the table
    const productsData = sessionStorage.getItem('productsData') && JSON.parse(sessionStorage.getItem('productsData'));
    if (!productsData || productsData.length < 1) return;

    const localCart = cart.getLocalCart();

    const rowTemplate = (product) => {
      if (!product) return '';
      const cartProduct = localCart?.cartProducts.find((item) => item.productId === product.id);

      const pricing = product?.pricing;
      // uncomment below if we need to show the discounts in the marketing website
      const unitPrice =
        cartProduct?.discountSource === 'Bulk'
          ? bulkOrder.formatAmount(cartProduct?.unitPriceWithDiscountAndFee).toFixed(2)
          : bulkOrder.formatAmount(pricing?.discountAmount).toFixed(2)
          ? bulkOrder.formatAmount(pricing?.price - pricing?.discountAmount).toFixed(2)
          : bulkOrder.formatAmount(pricing?.price).toFixed(2);
      const basePrice =
        cartProduct?.discountSource === 'Bulk'
          ? bulkOrder.formatAmount(cartProduct.baseUnitPrice).toFixed(2)
          : bulkOrder.formatAmount(pricing?.price).toFixed(2);
      // const unitPrice = pricing?.discountAmount
      //   ? (pricing?.price - pricing?.discountAmount).toFixed(2)
      //   : pricing?.price?.toFixed(2);
      const priceDisplay = '$' + unitPrice.toString();
      const agencyFee = product.pricing.agencyFee ? bulkOrder.formatAmount(product.pricing.agencyFee).toFixed(2) : 0;
      const quantity = cartProduct ? cartProduct.quantity : '';
      const discountAmount =
        cartProduct?.discountSource === 'Bulk' ? bulkOrder.formatAmount(cartProduct.unitDiscountAmount).toFixed(2) : '0.00';

      const bulkRates = JSON.stringify(product.productBulkTiers) || '[]';

      return `
        <tr class="d-flex flex-wrap d-sm-table-row">
          <td class="product-name-td align-middle">
            <span class="product-name d-block">${product.name}</span>
            <span class="bulk-description d-block ${
              bulkOrder.expandedDescription.includes(product.id) ? 'is-open' : ''
            }" data-pid="${product.id}">
              <span class="description-toggle small text-muted">View course description</span>
              <span class="description-content d-block">${product.description}</span>
            </span>

            ${product.pricing.agencyFee ? `<br><span class="small text-muted">Regulatory Fee: $${agencyFee}</span>` : ''}
          </td>
          <td class="flex-fill align-middle text-center" data-label="Quantity">
            <input
              class="form-control qty text-center"
              name="${product.id}"
              placeholder="0"
              type="number"
              pattern="[0-9]"
              data-price="${unitPrice}"
              data-fee="${agencyFee}"
              value="${quantity}"
            />
          </td>
          <td class="flex-fill align-middle text-end" data-label="Discount">
            <span
              class="bulk-rates-trigger"
              data-product-name="${product.name}"
              data-bulk-rates='${bulkRates}'
            >
              $${discountAmount}<span class="bulk-rates-icon">?</span>
            </span>
          </td>
          <td class="flex-fill align-middle text-end" data-label="Price"><strong>${priceDisplay}</strong></td>
        </tr>
      `;
    };

    let productDisplay = '';
    for (let productId of bulkProductIds) {
      const product = productsData.find((product) => product.id === productId);
      productDisplay += rowTemplate(product);
    }

    const bulkTable = bulkOrder.bulkTable;
    if (bulkTable) {
      bulkOrder.bulkBox.classList.remove('processing');
      const bulkTableBody = bulkTable.querySelector('tbody');

      bulkTableBody.innerHTML = productDisplay;

      bulkOrder.initQtyInputEvent(bulkTable);
      bulkOrder.initDiscountTiers(bulkTable);

      // calculate total amount
      const totalAmount = localCart.cartProducts.reduce((acc, cur) => {
        return bulkOrder.formatAmount(acc + cur.subTotal);
      }, 0);

      bulkOrder.initCartTotalAndBtn(totalAmount);
    }
  },

  initQtyInputEvent: (bulkTable) => {
    const inputCollection = [...bulkTable.querySelectorAll('input.qty')];

    // had to move the event listener into the input input instead of the document to prevent readding the event listener
    // when the bulk form is re-rendered
    for (let input of inputCollection) {
      input.addEventListener(
        'change',
        (event) => {
          const eventElement = event.target;
          const qtyInput = eventElement.closest('input.qty');
          if (qtyInput) {
            // console.log(qtyInput.value);
            const localCart = cart.getLocalCart();
            const productId = qtyInput.getAttribute('name');
            const quantity = qtyInput.value;
            const isInCart = localCart?.cartProducts.some((product) => product.productId === productId);

            bulkOrder.bulkBox.classList.add('processing');

            // calling any cart method will reinitialize the bulk order form with new data
            if (quantity > 0) {
              // if product doesn't exist in cart, add it to the cart
              if (!isInCart) {
                console.log('Adding product to cart!');
                cart.addToCart({ productId, quantity }, false, false);
              }
              // if product exist in cart, update the cart
              else {
                // call update cart method
                console.log('Updating cart!');
                cart.updateCart({ productId, quantity });
              }
            } else {
              // remove product from cart when qty is 0
              cart.removeFromCart(productId);
            }
          }
        },
        true
      );
    }
  },

  initDiscountTiers: (bulkTable) => {
    const modalTriggers = [...bulkTable.querySelectorAll('.bulk-rates-trigger')];
    const bulkRateModal = document.querySelector('#bulk-rate-modal');
    const modalTitle = bulkRateModal.querySelector('.product-name');
    const bulkRateInfoDisplay = bulkRateModal.querySelector('.bulk-rate-info');

    // comment and uncomment one of the boostrap modal
    // for bootstrap v4+
    // const bulkModal = $('#bulk-rate-modal');
    // for boostrap v5+
    const bulkModal = new bootstrap.Modal('#bulk-rate-modal');

    for (let trigger of modalTriggers) {
      trigger.addEventListener(
        'click',
        (event) => {
          const eventElement = event.target;
          const triggerEl = eventElement.closest('.bulk-rates-trigger');
          if (triggerEl) {
            // update modal information
            // compute each bulk tier and compose sentence like below
            // [{"minQuantity":10,"unitDiscount":2},{"minQuantity":20,"unitDiscount":5}]
            // Buy 10-19 and get a unit price of $248.00<br>
            // Buy 20 or more and get a unit price of $245.00

            modalTitle.innerHTML = triggerEl.dataset.productName;

            const bulkRates = JSON.parse(triggerEl.dataset.bulkRates);

            if (bulkRates && bulkRates.length > 0) {
              let bulkRateInfo = '';
              for (let i = 0; i < bulkRates.length; i++) {
                const bulkPrice = bulkOrder.formatAmount(bulkRates[i].discountTierUnitPrice).toFixed(2);
                if (i < bulkRates.length - 1) {
                  const tier = `${bulkRates[i].minQuantity}-${bulkRates[i + 1].minQuantity - 1}`;
                  bulkRateInfo += `Buy ${tier} and get a unit price of $${bulkPrice}<br>`;
                } else {
                  const tier = `${bulkRates[i].minQuantity} or more`;
                  bulkRateInfo += `Buy ${tier} and get a unit price of $${bulkPrice}`;
                }
              }
              bulkRateInfoDisplay.innerHTML = bulkRateInfo;
            }
            // if product doesn't have bulk tier pricing, either because it normally doesn't or because of the coupon or group ID
            else {
              bulkRateInfoDisplay.innerHTML = `<em>Complete the form below to speak to a sales representative.</em>`;
            }

            // comment and uncomment one of the boostrap modal triggers based on version of bootstrap
            // for bootstrap v4+
            // bulkModal.modal('show');
            // for boostrap v5+
            bulkModal.show();
          }
        },
        true
      );
    }
  },

  initCartTotalAndBtn: (totalAmount) => {
    const bulkTable = bulkOrder.bulkTable;
    const totalDisplay = bulkTable.querySelector('.total-display');
    const viewCartBtn = bulkTable.querySelector('.add-to-cart-bulk');

    if (totalDisplay) {
      totalDisplay.innerHTML = `$${totalAmount.toFixed(2)}`;
    }

    if (totalAmount > 0) {
      const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
      const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
      const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';

      // enable view cart button
      viewCartBtn.classList.remove('disabled');
      viewCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cart.getCartId()}${testPrice}${couponCode}${groupId}`;
    } else {
      // disable view cart button
      viewCartBtn.classList.add('disabled');
      viewCartBtn.href = '#';
    }
  },
};

(() => {
  document.addEventListener(
    'click',
    (e) => {
      const eventElement = e.target.closest('.description-toggle');
      // Check if the clicked element has the class "description-toggle"
      if (eventElement) {
        // Find the closest "description-content" element
        const contentElement = eventElement.parentNode;
        const productId = contentElement.dataset.pid;
        // Toggle the "is-open" class for the closest "description-content" element
        if (contentElement && !contentElement.classList.contains('is-open')) {
          contentElement.classList.add('is-open');
          // adding productId to array of opened description
          bulkOrder.expandedDescription.push(productId);
        } else {
          contentElement.classList.remove('is-open');
          // removing productId to array of opened description
          bulkOrder.expandedDescription.splice(bulkOrder.expandedDescription.indexOf(productId), 1);
        }
      }
    },
    false
  );
})();
