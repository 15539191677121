/* requires:
polyfill.js
*/

// host and testPrice values are not case sensitive
// groupId and tags value are case sensitive

const cart = {
  apiBaseUrl: 'cart_api_url_replaced_during_build',
  cartBaseUrl: 'cart_domain_replaced_during_build',
  unpublishedData: 'product_unpublished_replaced_during_build',

  init: () => {
    // remove this whole section when a solution for applying test price in cart exist
    // this removes and re-adds all the products on the cart with the correct prices
    const queryTestPrice = cart.getQueryString('tp') || 'a';
    const cookieTestPrice = cart.readCookie('_up4tp') || 'a';
    const queryCoupon = cart.getQueryString('cc');
    const cookieCoupon = cart.readCookie('_up4cc');
    const queryGroupId = cart.getQueryString('gid');
    const cookieGroupId = cart.readCookie('_up4gid');
    const localCart = JSON.parse(sessionStorage.getItem('localCart'));

    // workaround for when any of the price modifiers changes, cart doesn't apply new groupId by passing it as a query string
    // deleting and readding the products if there is a change in the testPrice/coupon/groupId value
    if (
      ((queryTestPrice && queryTestPrice !== cookieTestPrice) ||
        (queryCoupon && queryCoupon !== cookieCoupon) ||
        (queryGroupId && queryGroupId !== cookieGroupId)) &&
      localCart
    ) {
      console.log('Reinitializing the cart...');

      const cartId = localCart.cartId;
      const cartItems = localCart.cartProducts;
      for (let cartItem of cartItems) {
        const itemId = cartItem.cart_ProductId;
        const productId = cartItem.productId;
        const upsells = cartItem.upSellProducts ? cartItem.upSellProducts.map((upsell) => upsell.productId) : [];
        const quantity = cartItem.quantity;

        const opts = {
          method: 'DELETE',
        };
        const url = `${cart.apiBaseUrl}/CartProducts/${cartId}/${itemId}/?completeProducts=true`;

        fetch(url, opts)
          .then(checkFetchStatus)
          .then((data) => {
            if (data && data?.cartId) {
              // console.log(data)
              cart.addToCart({ productId, upsells, quantity }, true);
            } else {
              console.error('Item is not in the cart');
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    }
    // end of workaround section

    cart.fetchCart();
    cart.updateCartLink();
    cart.initAddToCartBtns();
    cart.initAddBtnEvent();
  },

  fetchCart: (id) => {
    const cartId = id || cart.getCartId();
    const url = `${cart.apiBaseUrl}/Carts/${cartId}/?completeProducts=true&unpublished=${cart.unpublishedData}`;
    if (cartId) {
      fetch(url)
        .then(checkFetchStatus)
        .then((data) => {
          // console.log('item count: ', data.item_count);
          if (data && data?.cartProducts?.length) {
            // console.log(fetchedCart)
            // console.log(data.cartProducts)
            sessionStorage.setItem('localCart', JSON.stringify(data));
            if (data.cartId !== cart.readCookie('cartId')) {
              cart.setCookie('cartId', data.cartId, 7);
            }
            cart.updateCartItemCount(data);
            cart.updateCartLink();
            cart.initAddToCartBtns();
          } else {
            console.log('There are no items in the cart.');
            // clear cart storage and use new session from API
            cart.setCookie('cartId', null, -1);
            sessionStorage.removeItem('localCart');
            cart.updateCartItemCount(cart.getLocalCart());
            cart.updateCartLink();
            cart.initAddToCartBtns();
          }

          // this function is from the bulk-order-form.js
          // this is dependent on fetching the cart because we display the existing product's data in the form
          bulkOrder.init();
        })
        .catch(function (err) {
          console.error(err);
        });
    } else {
      sessionStorage.removeItem('localCart');
    }
  },

  addToCart: (cartProduct, reAdding = false, redirectToCart = true) => {
    const { productId, upsells, quantity } = cartProduct;
    const defaultHost = 'default_host_replaced_during_build';
    const defaultGroupId = 'default_group_replaced_during_build';
    const cartId = cart.getCartId();
    const url = `${cart.apiBaseUrl}/CartProducts/?completeProducts=true&unpublished=${cart.unpublishedData}`;
    const product = JSON.parse(sessionStorage.getItem('productsData')).find((item) => item.id === productId);
    const cartData = {
      productId: product.id,
      quantity: quantity || 1,
      pricingUsed: product.pricing.typePricing, // need to this pull from pricing data
      couponCode: cart.readCookie('_up4cc'), // need to this pull from the cookies _up4cc
      sourceHost: defaultHost, // need to this pull from config.js, there will only be one host per site
      groupId: product.pricing.groupId || defaultGroupId || null, // need to this pull from pricing data
      selectedUpsellProductIds: upsells || [], // array of upsell GUIDs for this product, pulled from button data attribute
    };

    // below is needed just so we can redirect to the cart after adding the product
    const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
    const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
    const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';

    // using the cartId if there's a session cartId
    if (cartId) {
      cartData.cartId = cartId;
    }

    const opts = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cartData),
    };

    fetch(url, opts)
      .then(checkFetchStatus)
      .then((data) => {
        if (data.cartId) {
          // console.log(data);
          // reinitialized localCart with updated cart info
          cart.setCookie('cartId', data.cartId, 7);
          sessionStorage.setItem('localCart', JSON.stringify(data));
          cart.updateCartItemCount(data);
          cart.updateCartLink();
          cart.initAddToCartBtns();

          bulkOrder.init();

          if (!reAdding) {
            cart.pushAddToCartDatalayer(cartData.productId);
          }
          if (redirectToCart) {
            // the below is needed just so we can redirect to the cart after adding the product
            window.location.href = `${cart.cartBaseUrl}/cart/?cartId=${data.cartId}${testPrice}${couponCode}${groupId}`;
          }
        } else {
          console.error('There are no items in the cart.');
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },

  updateCart: (cartProduct) => {
    const { productId, quantity } = cartProduct;
    const localCart = cart.getLocalCart();
    const localCartProduct = localCart.cartProducts.find((product) => product.productId === productId);
    const url = `${cart.apiBaseUrl}/CartProducts/?completeProducts=true&unpublished=${cart.unpublishedData}`;
    const cartData = {
      cart_ProductId: localCartProduct.cart_ProductId,
      cartId: localCart.cartId,
      productId: productId,
      quantity: quantity,
      isSelected: false,
    };

    const opts = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cartData),
    };

    fetch(url, opts)
      .then(checkFetchStatus)
      .then((data) => {
        if (data.cartId) {
          sessionStorage.setItem('localCart', JSON.stringify(data));
          cart.updateCartItemCount(data);
          cart.updateCartLink();
          cart.initAddToCartBtns();

          bulkOrder.init();
        } else {
          console.error('The cart was not updated.');
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },

  removeFromCart: (productId) => {
    const localCart = cart.getLocalCart();
    const localCartProduct = localCart.cartProducts.find((product) => product.productId === productId);

    const opts = {
      method: 'DELETE',
    };
    const url = `${cart.apiBaseUrl}/CartProducts/${localCart.cartId}/${localCartProduct.cart_ProductId}/?completeProducts=true`;

    fetch(url, opts)
      .then(checkFetchStatus)
      .then((data) => {
        if (data.cartId) {
          sessionStorage.setItem('localCart', JSON.stringify(data));
          cart.updateCartItemCount(data);
          cart.updateCartLink();
          cart.initAddToCartBtns();

          bulkOrder.init();
        } else {
          console.error('The cart was not updated.');
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },

  pushAddToCartDatalayer: (productId) => {
    const pushData = (product) => {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: [
              {
                name: product.name,
                id: product.id,
                price: product.pricing.priceAfterDiscount || product.pricing.price,
                category: product.productCategory.categoryName,
                quantity: 1,
              },
            ],
          },
        },
      });
      if (window.gtag) {
        gtag('event', 'add_to_cart', {
          currency: 'USD',
          value: product.pricing.priceAfterDiscount || product.pricing.price,
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              affiliation: 'default_host_replaced_during_build',
              coupon: product.pricing.coupon || '',
              discount: product.pricing.discountAmount || 0,
              item_category: product.productCategory.categoryName,
              item_category2: product.productCategory.subCategoryName,
              item_category3: product.productCategory.verticalName,
              price: product.pricing.price,
              quantity: 1,
            },
          ],
        });
      }
    };
    if (sessionStorage.getItem('productsData')) {
      const product = JSON.parse(sessionStorage.getItem('productsData')).find((item) => item.id === productId);
      pushData(product);
    }
  },

  getCartId: () => {
    return cart.readCookie('cartId');
  },

  getLocalCart: () => {
    return (
      JSON.parse(sessionStorage.getItem('localCart')) || {
        cartId: null,
        cartProducts: [],
      }
    );
  },

  updateCartItemCount: (cart) => {
    const itemCount = cart.cartProducts.reduce((previousItem, currentItem) => previousItem + currentItem.quantity, 0);
    const itemCountDisplays = document.querySelectorAll('a.view-cart .item-count');
    for (let itemCountDisplay of itemCountDisplays) {
      // console.log(itemCount)
      itemCountDisplay.innerHTML = itemCount || 0;
    }
  },

  updateCartLink: () => {
    const cartKey = cart.readCookie('cartId');
    const viewCartLinks = document.querySelectorAll('a.view-cart');
    for (let viewCartLink of viewCartLinks) {
      if (cartKey) {
        viewCartLink.href = cart.updateQueryString(viewCartLink.href, 'cartId', cartKey);
      }
    }
  },

  initAddBtnEvent: () => {
    document.addEventListener(
      'click',
      (event) => {
        const eventElement = event.target;
        const addToCartBtn = eventElement.closest('a[data-pid]');

        if (addToCartBtn) {
          if (!addToCartBtn.classList.contains('view-cart-btn')) {
            event.preventDefault();
            addToCartBtn?.classList.add('btn-processing');
            const productId = addToCartBtn?.dataset.pid;
            const cartFlow = addToCartBtn?.dataset.cart;
            // add to cart button required to have the upsell IDs, can have multiple upsells IDs
            // data-upsells="7804b4c5-7aa3-4097-9622-08dabcee2708, 6264d1fd-7607-42f2-9667-3b4f24957e07"
            const upsellsData = addToCartBtn?.dataset.upsells;
            const upsells = upsellsData ? upsellsData.replace(/\s/g, '').split(',') : null;

            // data-cart='add' signals that the CTA will add the products to the cart without redirecting to the cart
            if (cartFlow === 'add') {
              // does not redirect to the cart after adding the product to the cart
              // cart.addToCart(cartProduct: object, reAdding?: boolean, redirectToCart?: boolean)
              cart.addToCart({ productId, upsells, quantity: 1 }, false, true);
            }
            // CTA will clear existing cart and redirected to the cart
            else {
              // normal single product path behavior
              // clear existing cart and then add the product to the cart and redirect to the cart
              cart.deleteCookie('cartId');
              sessionStorage.removeItem('localCart');
              // cart.addToCart(cartProduct: object, reAdding?: boolean, redirectToCart?: boolean)
              cart.addToCart({ productId, upsells, quantity: 1 });
            }
          }
        }
      },
      true
    );
  },

  initAddToCartBtns: () => {
    const addToCartBtns = document.querySelectorAll('a[data-pid]');
    const cartCookie = cart.readCookie('cartId');
    const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
    const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
    const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';
    const localCart = cart.getLocalCart();

    for (let addToCartBtn of addToCartBtns) {
      const productId = addToCartBtn.dataset.pid;
      const cartFlow = addToCartBtn?.dataset.cart;

      addToCartBtn.classList.remove('btn-processing');

      // if CTA's product is already in the local cart and there's an existing cart
      if (localCart?.cartProducts.some((product) => product.productId === productId) && cartCookie) {
        // addToCartBtn.innerHTML = 'View In Cart';
        if (cartFlow === 'add' || localCart?.cartProducts.length === 1) {
          // if (cartFlow === 'add') {
          //   addToCartBtn.innerHTML = 'View In Cart';
          // }
          addToCartBtn.classList.add('view-cart-btn');
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cartCookie}${testPrice}${couponCode}${groupId}`;
        } else {
          addToCartBtn.classList.remove('view-cart-btn');
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?productId=${productId}${testPrice}${couponCode}${groupId}`;
        }
      }
      // if CTA's product is not in the local cart
      else {
        addToCartBtn.classList.remove('view-cart-btn');
        // addToCartBtn.innerHTML = 'Add To Cart';
        addToCartBtn.href = `${cart.cartBaseUrl}/cart/?productId=${productId}${testPrice}${couponCode}${groupId}`;
      }
    }
  },

  readCookie: function (name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },

  setCookie: function (name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    const domain =
      location.hostname == 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },

  deleteCookie: function (name) {
    const location = window.location;
    const domain =
      location.hostname === 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname === 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' + domain + security;
  },

  getQueryString: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  updateQueryString: function (uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  },
};

cart.init();
